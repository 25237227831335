import React from 'react';
import {graphql} from 'gatsby';
import Page from '../components/Page';

const Feed = ({data, pageContext}) => {
  const {currentPage, numPages} = pageContext;
  const feedItems = data.allFeedItem.edges.map(({node}) => node);
  return (
    <Page
      title="Home"
      hideTitle
      path="/"
      description="The latest tech news, thoughts, and opinion from sources like Wired, Techcrunch, a16z, and Stratechery."
      feedItems={feedItems}
      searchPages={data.localSearchPages}
      breadcrumb="/"
      pagination={{
        currentPage,
        numPages,
      }}
    />
  );
};

export default Feed;

export const FEED_ITEM_FRAGMENT = graphql`
  fragment FeedItemFragmentHome on FeedItem {
    id
    pubDate
    title
    link
    author
    content
    ttr
    parent {
      ... on Feed {
        title
        path
        twitter
      }
    }
  }
`;

export const query = graphql`
  query HomeQuery($skip: Int!, $limit: Int!) {
    allFeedItem(
      sort: {fields: [pubDate], order: DESC}
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          ...FeedItemFragmentHome
        }
      }
    }
  }
`;
